// React Helpers

export const isset = function (variable) {
  return typeof variable !== "undefined" && variable !== null && variable !== ""
}

export const isPresent = function (variable) {
  return isset(variable)
}

export const isBlank = function (variable) {
  return !isset(variable)
}

export const isNum = function (number) {
  return !isNaN(number)
}

export const isObject = function (value) {
  return value && typeof value === "object" && value.constructor === Object
}

export const hasAny = function (array) {
  return Array.isArray(array) && array.length > 0
}

export const isEmpty = function (array) {
  return !hasAny(array)
}

export const isEmptyObject = function (object) {
  return isObject(object) && Object.keys(object).length === 0
}

export const definedOrNA = function (val) {
  return isset(val) ? val : "N/A"
}

export const arrayToSentence = function (arr) {
  const last = arr.pop()
  if (arr.length === 0) {
    return last
  }
  return arr.join(", ") + (arr.length > 1 ? ", and " : " and ") + last
}

export const getUrlParam = function (name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.search
      ) || [null, ""])[1].replace(/\+/g, "%20")
    ) || null
  )
}

export const buildRoute = function (route, tokens) {
  // Takes a route string and replaces tokens with the format ':token'.
  // Good for building a route on the back end using tokens rather than values
  // so you can build a route with dynamic content on the front end.
  // Example: some/route/:id/new -> buildRoute($context.someRoute, {id: 1}) -> some/route/1/new
  _.each(tokens, (value, key) => {
    route = route.replace(`:${key}`, value)
  })
  return route
}

export const buildApiRoute = function (path) {
  return DockwaConfig.API_BASE_URL + "/api/1.0/" + path
}

export const buildPdfModalRoute = function (url) {
  return `${DockwaConfig.BASE_URL}/pdfs?pdf_url=${encodeURIComponent(url)}`
}

export const centsToDollars = function (cents, precision = 2) {
  let prefix = ""

  if (cents < 0) {
    prefix = "-"
  }

  return (
    prefix +
    (Math.abs(cents) / 100).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: precision,
      style: "currency",
      currency: "USD",
    })
  )
}

export const centsToDollarsRaw = function (cents, precision = 2) {
  return (cents / 100).toFixed(precision)
}

export const fuelHundredthsCentsToDollarsRaw = function (
  hundredthsCents,
  precision = 4
) {
  return (hundredthsCents / 10000).toFixed(precision)
}

export const dollarsToCents = function (dollars) {
  // this needs rounding due to float math
  // parseFloat(520.30) * 100
  // => 52029.99999999999
  return Math.round(dollars * 100)
}

export const safePercentageDecimal = function (percentWithDecimals) {
  if (percentWithDecimals === null) {
    return null
  }
  // this needs decimal rounding due to float math ie 12.84 -> 12.8333339
  const numDecimals = countNeededDecimals(percentWithDecimals)
  return (percentWithDecimals * 100).toFixed(numDecimals)
}

export const countNeededDecimals = function (value) {
  if (Math.floor(value) === value) {
    return 0
  }
  const numDecimals = value.toString().split(".")[1].length || 0

  if (numDecimals >= 2) {
    return numDecimals - 2
  } else {
    return 0
  }
}

export const collectionKeysToSnakeCase = function (collection) {
  return collection.map((object) => _.mapKeys(object, objectKeysToSnakeCase))
}

export const objectKeysToSnakeCase = function (val, key) {
  return camelCaseToSnakeCase(key)
}

export const camelCaseToSnakeCase = function (string) {
  return string
    .replace(/[\w]([A-Z])/g, (match) => {
      return match[0] + "_" + match[1]
    })
    .toLowerCase()
}

export const snakeCaseToReadable = function (string) {
  return titleCase(string.replace(/total_/g, "").replace(/_/g, " "))
}

export const titleCase = function (string) {
  return string.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const capitalizeFirstLetter = function (string) {
  return isset(string) ? string.charAt(0).toUpperCase() + string.slice(1) : ""
}

export const numbersOnly = function (val) {
  return val.replace(/[^0-9]/g, "")
}

export const floatsOnly = function (val) {
  return val.replace(/[^0-9.-]/g, "")
}

export const inchesToFeet = function (inches) {
  return Math.ceil(inches / 12)
}

export const inchesToSquareFeet = function (inches) {
  return Math.ceil(inches / 144)
}

export const feetToInches = function (feet) {
  return feet * 12
}

export const boatDisplayText = function (name, loa, type) {
  return name + ", " + loa + "' " + capitalizeFirstLetter(type)
}

export const displayBoatType = function (type) {
  if (type === "sail") {
    return "Sailboat"
  } else if (type === "power") {
    return "Power Boat"
  } else {
    return "Not Specified"
  }
}

export const metaStatus = function (
  status,
  checkedIn,
  checkedOut,
  checkOutDate
) {
  if (status === "confirmed") {
    if (checkOutDate && moment(checkOutDate).isBefore(moment())) {
      return "completed"
    }
    if (checkedIn && !checkedOut) {
      return "checked_in"
    }
    if (checkedOut) {
      return "checked_out"
    }
  }
  return status
}

// PosItem & Sales helpers

export const metaFuel = function (category) {
  return ["fuel", "gas", "diesel"].includes(category)
}

export const isListedPumpPrice = function (chargeItem) {
  if (!$context.listedPumpPrices)
    throw new Error(
      "must set this javascript_context(listedPumpPrices: PosItemTemplate::LISTED_PUMP_PRICES) within the rails controller"
    )

  return (
    chargeItem &&
    Object.keys($context.listedPumpPrices).includes(chargeItem.name) &&
    chargeItem.frequency === "one_time" &&
    metaFuel(chargeItem.category)
  )
}

export const orderedAvailableCategories = function (
  posItemTemplates,
  orderedCategories
) {
  const availableCategories = _.uniq(
    posItemTemplates.map((item) => item.category)
  )
  return _.filter(orderedCategories, (category) =>
    availableCategories.includes(category)
  )
}

export const formatPriceForCategory = function (price, category) {
  const precision = metaFuel(category) ? 4 : 2
  return centsToDollars(price, precision)
}

export const badgeForReservationStatus = function (status, reservationLabel) {
  switch (status) {
    case "pending":
    case "created":
    case "requested":
      return "badge-yellow"
    case "confirmed":
    case "accepted":
      if (reservationLabel) {
        return `rl-bg-${reservationLabel.color}-light`
      } else {
        return "badge-blue"
      }
    case "checked_in":
      if (reservationLabel) {
        return `rl-bg-${reservationLabel.color}-dark`
      } else {
        return "badge-blue-inverted"
      }
    case "checked_out":
    case "expired":
      return "badge-gray"
    case "completed":
      return "badge-teal"
    case "canceled":
    case "payment_declined":
    case "declined":
      return "badge-red"
    case "waitlisted":
      return "badge-orange"
    default:
      return "badge-gray"
  }
}

export const badgeForPaymentStatus = function (status) {
  switch (status) {
    case "processing":
    case "scheduled":
      return "badge-yellow"
    case "paid":
      return "badge-teal"
    case "failed":
      return "badge-red"
    default:
      return "badge-gray"
  }
}

export const badgeForInquiryStatus = function (status) {
  switch (status) {
    case "pending":
      return "badge-yellow"
    case "in_progress":
    case "accepted":
      return "badge-blue"
    case "completed":
      return "badge-teal"
    case "declined":
    case "canceled":
      return "badge-red"
    case "expired":
      return "badge-gray"
    default:
      return "badge-gray"
  }
}

export const friendlyInstallmentScheduleName = function (schedule) {
  switch (schedule) {
    case "upfront":
      return "Upfront"
    case "check_in":
      return "Check-in"
    case "check_out":
      return "Check-out"
    case "deposit_one_night":
      return "Deposit (one night)"
    case "deposit_quarter":
      return "Deposit (25%)"
    case "deposit_half":
      return "Deposit (50%)"
    case "monthly":
      return "Monthly"
  }
}

export const friendlyPricingStructureName = function (pricingStructure) {
  switch (pricingStructure) {
    case "per_day":
      return "Daily flat rate"
    case "by_foot_per_day":
      return "Daily per foot"
    case "sqft_per_day":
      return "Daily per square foot"
    case "per_month":
      return "Monthly flat rate"
    case "by_foot_per_month":
      return "Monthly per foot"
    case "sqft_per_month":
      return "Monthly per square foot"
    case "per_stay":
      return "Full stay flat rate"
    case "by_foot_per_stay":
      return "Full stay per foot"
    case "sqft_per_stay":
      return "Full stay per square foot"
  }
}

export const afterSetState = function (callback, delay = 10) {
  setTimeout(callback, delay)
}

export const updateUrlQueryParams = function (query) {
  const updatedUrl = new URL(window.location.href)
  _.each(query, (value, key) => {
    if (value) {
      updatedUrl.searchParams.set(key, value)
    } else {
      updatedUrl.searchParams.delete(key)
    }
  })
  window.history.replaceState(null, null, updatedUrl.toString())
}

export const areSame = function (array1, array2) {
  if (!isset(array1) || !isset(array2)) {
    return array1 === array2
  } else {
    return array1.toString() === array2.toString()
  }
}

export const humanize = function (str) {
  const frags = str.split("_")
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(" ")
}

Array.prototype.indexOf ||
  // eslint-disable-next-line no-extend-native
  (Array.prototype.indexOf = function (d, e) {
    let a
    if (this == null) throw new TypeError('"this" is null or not defined')
    const c = Object(this)
    const b = c.length >>> 0
    if (b === 0) return -1
    a = +e || 0
    Infinity === Math.abs(a) && (a = 0)
    if (a >= b) return -1
    for (a = Math.max(a >= 0 ? a : b - Math.abs(a), 0); a < b; ) {
      if (a in c && c[a] === d) return a
      a++
    }
    return -1
  })

export const numberWithDelimiter = function (num, delimiter = ",") {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
}

export const formatPhoneNumber = function (num) {
  if (num === null) {
    return num
  }

  const str = num.toString()

  const matched = str.match(/\d+\.?\d*/g)
  if (matched == null) {
    return num
  }

  // 10 digit
  if (matched.length === 3) {
    return "(" + matched[0] + ") " + matched[1] + "-" + matched[2]
    // 7 digit
  } else if (matched.length === 2) {
    return matched[0] + "-" + matched[1]
  } else if (matched.length === 1) {
    // no formatting attempted only found integers (i.e. 1234567890)
    // 10 digit
    if (matched[0].length === 10) {
      return (
        "(" +
        matched[0].substr(0, 3) +
        ") " +
        matched[0].substr(3, 3) +
        "-" +
        matched[0].substr(6)
      )
    }
    // 7 digit
    if (matched[0].length === 7) {
      return matched[0].substr(0, 3) + "-" + matched[0].substr(3)
    }
  }

  // Format failed, return number back
  return num
}

export const urlSafeBase64Encode = function (string) {
  return btoa(escape(string))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "")
}

export const handleInputChange = function (_this, event) {
  const target = event.target
  const value = target.type === "checkbox" ? target.checked : target.value
  const names = target.name.split(".")
  if (names.length > 1) {
    _this.setState({
      [names[0]]: { ..._this.state[names[0]], [names[1]]: value },
    })
  } else {
    _this.setState({
      [names[0]]: value,
    })
  }
}

export const parseJSONError = function (text, customField = null) {
  try {
    const json = JSON.parse(text)
    if (customField) {
      return json[customField]
    }
    return json.error || json.errors
  } catch (_error) {
    return null
  }
}

export const paymentMethodDisplayText = function (paymentMethod) {
  const paymentMethodDisplayItems = [
    paymentMethod.title,
    paymentMethod.subtitle,
  ]

  if (paymentMethod.type === "PaymentMethod::Card") {
    paymentMethodDisplayItems.push(`(${paymentMethod.metadata.funding})`)
  }

  return paymentMethodDisplayItems.join(" ")
}

// taken from: https://github.com/bevacqua/fuzzysearch/blob/master/index.js
// however the package uses module loading so we can't use it
export const fuzzysearch = function (needle, haystack) {
  const hlen = haystack.length
  const nlen = needle.length
  if (nlen > hlen) {
    return false
  }
  if (nlen === hlen) {
    return needle === haystack
  }
  // eslint-disable-next-line no-labels
  outer: for (let i = 0, j = 0; i < nlen; i++) {
    const nch = needle.charCodeAt(i)
    while (j < hlen) {
      if (haystack.charCodeAt(j++) === nch) {
        // eslint-disable-next-line no-labels
        continue outer
      }
    }
    return false
  }
  return true
}

export const patchRequest = function (url, params, func) {
  if (!isObject(params)) {
    func = params
    params = {}
  }
  params._method = "patch"
  return jQuery.post(url, params, func)
}

export const deleteRequest = function (url, params, func) {
  if (!isObject(params)) {
    func = params
    params = {}
  }
  params._method = "delete"
  return jQuery.post(url, params, func)
}

export const isRunningStandalone = function () {
  return window.matchMedia("(display-mode: standalone)").matches
}

export const friendlyPricingStructureUnitName = function (pricingStructure) {
  switch (pricingStructure) {
    case "per_day":
      return "per night"
    case "per_month":
      return "per month"
  }
}
