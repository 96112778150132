import React from "react"

import { handleInputChange } from "../utils/helpers"

export default class ApplicationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    handleInputChange(this, event)
  }
}
