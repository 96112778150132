import PropTypes from "prop-types"
import React from "react"
import NextButton from "src/legacy/components/BoaterReservationFlow/NextButton"
import RenderInBody from "src/legacy/components/RenderInBody"

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.modal = null
    this.setModalRef = (element) => {
      this.modal = element
    }
  }

  componentDidMount() {
    // Add a small delay to ensure DOM is fully rendered
    setTimeout(() => {
      if (this.modal) {
        $(this.modal).modal({ show: true })
        $(this.modal).on("hidden.bs.modal", this.props.closeModal)
      }
    }, 50)
  }

  componentWillUnmount() {
    if (this.modal) {
      $(this.modal).modal("hide")
    }
  }

  render() {
    return (
      <RenderInBody>
        <div
          className={`modal fade ${this.props.customClassName}`}
          ref={this.setModalRef}
        >
          <div className="modal-dialog" style={this.props.modalDialogStyles}>
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">{this.props.title}</h4>
                {this.props.subtext && (
                  <div className="text-muted">{this.props.subtext}</div>
                )}
              </div>
              <div className="modal-body">{this.props.children}</div>
              <div className="modal-footer">
                {this.props.footerLink}
                <button
                  type="button"
                  className={
                    this.props.cancelBtnClass || "btn btn-secondary mr-3"
                  }
                  style={{ width: "70px" }}
                  data-dismiss="modal"
                >
                  {this.props.cancelBtnText || "Cancel"}
                </button>
                {!this.props.onlyCancel && (
                  <NextButton
                    btnClass={this.props.saveBtnClass || "btn-primary"}
                    disabled={this.props.disabledBtn}
                    processing={this.props.saving}
                    click={this.props.saveBtnClick}
                    icon={this.props.btnIconClassName}
                    text={this.props.saveBtnText || "Save"}
                    processingText={
                      this.props.saveBtnTextProcessing || " Saving..."
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </RenderInBody>
    )
  }
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  customClassName: PropTypes.string,
  modalDialogStyles: PropTypes.object,
  footerLink: PropTypes.element,
  cancelBtnText: PropTypes.string,
  cancelBtnClass: PropTypes.string,
  onlyCancel: PropTypes.bool,
  saveBtnText: PropTypes.string,
  saveBtnTextProcessing: PropTypes.string,
  saveBtnClass: PropTypes.string,
  disabledBtn: PropTypes.bool,
  saving: PropTypes.bool,
  saveBtnClick: PropTypes.func,
  btnIconClassName: PropTypes.string,
  closeModal: PropTypes.func,
  children: PropTypes.node,
}

export default Modal
