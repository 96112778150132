import PropTypes from "prop-types"
import React from "react"
import Modal from "src/legacy/components/Modal"
import { deleteRequest } from "src/legacy/utils/helpers"

class CancelReservationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      cancellationReason: "",
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.save = this.save.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  openModal() {
    this.setState({ showModal: true })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  save() {
    this.setState({ saving: true })
    this.submit({
      method: "delete",
      cancellation_reason: this.state.cancellationReason,
    })
  }

  submit(params) {
    deleteRequest(this.props.endpoint, params, () => {
      location.reload()
    }).fail((data) => {
      let error = "Failed to cancel."
      if (data.responseText && JSON.parse(data.responseText).error) {
        error = JSON.parse(data.responseText).error
      }
      this.setState({ errorMessage: error })
    })
  }

  handleChange(event) {
    this.setState({
      cancellationReason: event.target.value,
    })
  }

  invalidCancellationReason() {
    return this.state.cancellationReason.trim().length === 0
  }

  render() {
    return (
      <span>
        <button
          type="button"
          className={this.props.btnClassName}
          onClick={this.openModal}
          dangerouslySetInnerHTML={{
            __html:
              "<i class='icon icon-times text-xs mr-2'></i>" +
              this.props.btnName,
          }}
        />
        {this.state.showModal && (
          <Modal
            closeModal={this.closeModal}
            saving={this.state.saving}
            title="Are you sure you want to cancel?"
            saveBtnText="Cancel Reservation"
            saveBtnClick={this.save}
            saveBtnClass={"btn btn-danger"}
            saveBtnTextProcessing="Cancelling.."
            disabledBtn={this.invalidCancellationReason()}
          >
            {
              <div>
                <p>
                  By canceling your reservation you agree to the
                  marina&apos;s&nbsp;
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={this.props.cancellationPolicy}
                  >
                    cancellation policy
                  </a>
                  .<br />
                  <b>Please enter a reason for canceling your reservation.</b>
                </p>
                <textarea
                  className="form-control"
                  type="text"
                  value={this.state.cancellationReason}
                  onChange={this.handleChange}
                />
              </div>
            }
          </Modal>
        )}
      </span>
    )
  }
}

CancelReservationModal.propTypes = {
  btnClassName: PropTypes.string,
  btnName: PropTypes.string,
  endpoint: PropTypes.string,
  cancellationPolicy: PropTypes.string,
}

export default CancelReservationModal
