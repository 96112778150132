import PropTypes from "prop-types"
import React from "react"
import { createRoot } from "react-dom/client"

class RenderInBody extends React.Component {
  constructor(props) {
    super(props)
    this.popup = document.createElement("div")
    this.root = null
  }

  componentDidMount() {
    document.body.appendChild(this.popup)
    this.root = createRoot(this.popup)
    this._renderLayer()
  }

  componentDidUpdate() {
    this._renderLayer()
  }

  componentWillUnmount() {
    // Add a small delay to avoid synchronous unmounting during render
    setTimeout(() => {
      if (this.root) {
        this.root.unmount()
      }
      if (this.popup && this.popup.parentNode) {
        document.body.removeChild(this.popup)
      }
    }, 0)
  }

  _renderLayer() {
    if (this.root) {
      this.root.render(this.props.children)
    }
  }

  render() {
    return null
  }
}

RenderInBody.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RenderInBody
