import PropTypes from "prop-types"
import React from "react"
import Modal from "src/legacy/components/Modal"

class ShareReservationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      error: false,
      responseMessage: "",
      email: "",
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.save = this.save.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.hideResponse = this.hideResponse.bind(this)
  }

  openModal() {
    this.setState({ showModal: true })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  hideError() {
    this.setState({ errorMessage: "" })
  }

  hideSuccess() {
    this.setState({ successMessage: "" })
  }

  save() {
    this.setState({
      saving: true,
      errorMessage: "",
      successMessage: "",
    })
    this.submit({
      method: "post",
      email: this.state.email,
    })
  }

  submit(params) {
    $.post(this.props.endpoint, params, (data, msg) => {
      this.setState({
        responseMessage: `An email with your reservation was sent to ${this.state.email}`,
        error: false,
        saving: false,
        email: "",
      })
    }).fail((data) => {
      this.setState({
        responseMessage: `An error occured while trying to share your reservation to ${this.state.email}. Please make sure the email is entered correctly and it is a valid email.`,
        error: true,
        saving: false,
      })
    })
  }

  handleChange(event) {
    this.setState({
      email: event.target.value,
    })
  }

  invalidCancellationReason() {
    return this.state.email.trim().length === 0
  }

  hideResponse() {
    this.setState({
      responseMessage: "",
    })
  }

  render() {
    return (
      <span>
        <button
          type="button"
          className={this.props.btnClassName}
          onClick={this.openModal}
          dangerouslySetInnerHTML={{
            __html:
              "<i class='icon icon-share text-xs mr-2'></i>" +
              this.props.btnName,
          }}
        />
        {this.state.showModal && (
          <Modal
            closeModal={this.closeModal}
            saving={this.state.saving}
            title="Share Your Reservation"
            saveBtnText="Share"
            saveBtnClick={this.save}
            saveBtnClass={"btn btn-primary"}
            saveBtnTextProcessing="Sharing.."
            disabledBtn={this.invalidCancellationReason()}
          >
            <p>
              Please enter an email address to share your reservation. We will
              email them a copy of your itinerary.
            </p>
            <p>
              <input
                placeholder="Email"
                className="form-control"
                type="text"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </p>
            {this.state.responseMessage && (
              <div
                className={
                  (this.state.error ? "alert-danger" : "alert-success") +
                  " alert alert-dismissible show"
                }
                role="alert"
              >
                {this.state.responseMessage}
                <button
                  onClick={this.hideResponse}
                  className="close"
                  aria-label="Close"
                  type="reset"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
          </Modal>
        )}
      </span>
    )
  }
}

ShareReservationModal.propTypes = {
  btnClassName: PropTypes.string,
  btnName: PropTypes.string,
  endpoint: PropTypes.string,
}

export default ShareReservationModal
