import PropTypes from "prop-types"
import React from "react"
import Modal from "src/legacy/components/Modal"
import { paymentMethodDisplayText } from "src/legacy/utils/helpers"

class SelectAndAddPaymentMethodModal extends React.Component {
  constructor(props) {
    super(props)

    const firstPaymentMethod =
      this.props.paymentMethods &&
      this.props.paymentMethods[0] &&
      this.props.paymentMethods[0].stripePaymentMethodId

    this.state = {
      showModal: false,
      selectedPaymentMethodId: firstPaymentMethod,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.save = this.save.bind(this)
    this.stopLoading = this.stopLoading.bind(this)
    this.setSelectedPaymentMethod = this.setSelectedPaymentMethod.bind(this)
  }

  openModal() {
    this.setState({ showModal: true })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  save() {
    this.setState({ saving: true })
    this.submit({ card_id: this.state.selectedPaymentMethodId })
  }

  setSelectedPaymentMethod(event) {
    this.setState({ selectedPaymentMethodId: event.target.value })
  }

  submit(params) {
    $.post(this.props.endpoint, params, () => {
      location.reload()
    }).fail((data) => {
      let error = "Failed to save payment method."
      if (data.responseText && JSON.parse(data.responseText).error) {
        error = JSON.parse(data.responseText).error
      }
      this.setState({ errorMessage: error })
      this.stopLoading()
    })
  }

  modalLinkToEditPaymentMethods() {
    return (
      <a
        href={this.props.paymentIndexPath}
        rel="noopener noreferrer"
        className="plain-link float-left pt-2"
      >
        {"Manage Payment Methods"}
      </a>
    )
  }

  paymentMethodSelector() {
    if (this.props.paymentMethods) {
      return (
        <div className="form-group">
          <label>Select Payment Method</label>
          <select
            className="form-control"
            value={this.state.selectedPaymentMethodId}
            onChange={this.setSelectedPaymentMethod}
          >
            {this.props.paymentMethods.map((paymentMethod) => {
              return (
                <option
                  key={paymentMethod.id}
                  value={paymentMethod.stripePaymentMethodId}
                >
                  {paymentMethodDisplayText(paymentMethod)}
                </option>
              )
            })}
            {this.props.paymentMethods.length === 0 && (
              <option value="">No additional payment methods available</option>
            )}
          </select>
        </div>
      )
    }
  }

  stopLoading() {
    this.setState({ saving: false })
  }

  render() {
    return (
      <span>
        <button
          type="button"
          className={this.props.btnClassName}
          onClick={this.openModal}
          dangerouslySetInnerHTML={{
            __html: this.props.btnName || "Add New Payment Method",
          }}
        />
        {this.state.showModal && (
          <Modal
            closeModal={this.closeModal}
            saving={this.state.saving}
            title="Update Payment Method"
            saveBtnText="Update"
            disabledBtn={!this.state.selectedPaymentMethodId}
            saveBtnClick={this.save}
            btnIconClassName="icon-lock-fa bs-mr-3"
            footerLink={this.modalLinkToEditPaymentMethods()}
          >
            {this.paymentMethodSelector()}
            {this.props.infoText && (
              <div className="text-muted">{this.props.infoText}</div>
            )}
          </Modal>
        )}
      </span>
    )
  }
}

SelectAndAddPaymentMethodModal.propTypes = {
  endpoint: PropTypes.string.isRequired,
  paymentMethods: PropTypes.array,
  paymentIndexPath: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  btnName: PropTypes.string,
  infoText: PropTypes.string,
}

export default SelectAndAddPaymentMethodModal
