import React from "react"
import ApplicationComponent from "src/legacy/core/ApplicationComponent"
import { isset } from "src/legacy/utils/helpers"

class NextButton extends ApplicationComponent {
  icon() {
    const names = {
      icon: true,
      "bs-mr-2": true,
      "icon-spinner": this.props.processing,
      "icon-spin": this.props.processing,
    }
    if (isset(this.props.icon) && !this.props.processing) {
      names[this.props.icon] = true
    }
    if (isset(this.props.icon) || this.props.processing) {
      return <i className={classNames(names)} />
    }
  }

  text() {
    if (this.props.processing === true) {
      return this.props.processingText
    } else {
      return this.props.text
    }
  }

  btnClass() {
    return isset(this.props.btnClass)
      ? "btn " + this.props.btnClass
      : "btn btn-secondary"
  }

  render() {
    return (
      <button
        id={this.props.id}
        type="button"
        className={this.btnClass()}
        disabled={this.props.processing || this.props.disabled}
        onClick={this.props.click}
        data-confirm={this.props.confirm}
      >
        {this.icon()}
        {this.text()}
      </button>
    )
  }
}

export default NextButton
